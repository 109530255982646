// controller
import useGetData from "../../../hook/getData";
import { GetComments } from "../../../services/panel/comments/getComments";

const Comments = () => {
    const { data: comments } = useGetData(['userComments'], GetComments)
    return (
        <>
            <h6 className="fw-bold mb-0">نظرات</h6>
            <hr />
            <div className="table-responsive">
                <table className="table table-bordered text-center font-medium align-middle">
                    <thead>
                        <tr>
                            <th>ردیف</th>
                            <th>متن کامنت</th>
                            <th>بر روی محصول</th>
                            <th>وضعیت</th>
                        </tr>
                    </thead>
                    <tbody>
                        {comments?.map((comment: any, index: number) => (
                            <tr key={index + 'order'}>
                                <td>
                                    {index + 1}
                                </td>
                                <td>
                                    {comment.text}
                                </td>
                                <td>
                                    {comment.product?.name}
                                </td>
                                <td className="fw-bold">
                                    <button className="btn btn-primary font-medium">
                                        {comment.approved}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Comments;
