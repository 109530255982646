// link
import { NavLink } from "react-router-dom";

const MobileNav = (props: object | any) => {
    return (
        <>
            <section className="d-md-none d-inline-block mobile-menu d-flex justify-content-center position-relative">
                <div
                    className="header-mobile d-md-none d-inline-block position-fixed shadow">
                    <div className="container px-4">
                        <div className="d-flex align-items-center justify-content-between">
                            {props.routes.map((link: object | any, index: number) => (
                                <>
                                    {link.child ? (
                                        <>
                                            <div key={link.address} className={link.show ? 'btn-phone p-0 mx-1 text-blue' : 'd-none'}>
                                                <div
                                                    data-bs-toggle="modal" data-bs-target="#modal-menu">
                                                    <div className="activator d-flex justify-content-center align-items-center">
                                                        <button className="btn btn-outline-site p-0">
                                                            {link.icon}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div key={link.address} className={link.show ? 'btn-phone p-0 mx-1 text-blue' : 'd-none'}>
                                                <NavLink
                                                    to={link.address}>
                                                    <div className="activator d-flex justify-content-center align-items-center">
                                                        <button className="btn btn-outline-site p-0">
                                                            {link.icon}
                                                        </button>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </>
                                    )}
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MobileNav;