// hook
import { useCart } from "./../../context/cartContext";
import { IconTrash, IconX } from "@tabler/icons-react";
import { splitNumber } from "../../features/splitNumber/splitNumber";
// useEffect
import { useEffect, useState } from "react";
import { GetCarts } from "../../services/panel/cart/getCart";
import useGetData from "../../hook/getData";
// @ts-ignore
import Cookies from "js-cookie";
import { useMutation } from "@tanstack/react-query";
import { toastOptions } from "../../features/toast/toast";
import { toast } from "react-toastify";
import { EditCart } from "../../services/panel/cart/editCard";
import { RemoveCart } from "../../services/panel/cart/removeCart";
import { GetProvinces } from "../../services/panel/address/getProvinces";
import Select from 'react-select';
import { GetCities } from "../../services/panel/address/getCities";
import { useForm } from "react-hook-form";
import { onError } from "../../features/error/error";
import { SendAddress } from "../../services/panel/address/sendAddress";
import { GetAddress } from "../../services/panel/address/getAddress";
import { SendPayment } from "../../services/panel/payment/sendPayment";

const CartModal = () => {
    const { cart, increaseQty, decreaseQty, removeFromCart } = useCart();
    const { data: carts, isLoading, refetch } = useGetData(['carts'], GetCarts)
    const { data: provinces } = useGetData(['provinces'], GetProvinces);
    const { data: address, refetch: adrefe } = useGetData(['address'], GetAddress);
    let [cities, setCities] = useState([]);
    const { register, handleSubmit }: any = useForm<any>();
    const provinceOptions = provinces?.map((p: { id: any; name: any; }) => ({
        value: p.id,
        label: p.name
    }));
    const totalPrice = cart.reduce((sum, item) => sum + item.item_price * item.qty, 0);
    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById('pages-header')?.classList.remove('d-none');
    }, [])
    const Cartmutation = useMutation({
        mutationFn: EditCart,
        onSuccess: () => {
            refetch();
        },
        onError: (error: any) => {
            const errors: any = error.response.data?.message;
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });
    const rmmutation = useMutation({
        mutationFn: RemoveCart,
        onSuccess: () => {
            refetch();
        },
        onError: (error: any) => {
            const errors: any = error.response.data?.message;
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });
    const cityMutation = useMutation({
        mutationFn: GetCities,
        onSuccess: (data) => {
            let cityOptions = data?.map((p: { id: any; name: any; }) => ({
                value: p.id,
                label: p.name
            }));
            setCities(cityOptions)
        },
        onError: (error: any) => {
            const errors: any = error.response.data?.message;
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });
    const decreaseQtyL = (id: any, quantity: any) => {
        let data = {
            id: id,
            qtybutton: quantity - 1,
            "_method": "PUT",
        }
        Cartmutation.mutate(data)
    }
    const increaseQtyL = (id: any, quantity: any) => {
        let data = {
            id: id,
            qtybutton: quantity + 1,
            "_method": "PUT",
        }
        Cartmutation.mutate(data)
    }
    const removeFromCartL = (id: any) => {
        rmmutation.mutate(id)
    }
    const [value, setValue] = useState(null);

    const onChange = (selectedOption: any) => {
        setValue(selectedOption?.value);
        cityMutation.mutate(selectedOption?.value)
    };
    const [Cityvalue, setCityValue] = useState(null);

    const onChangeCity = (selectedOption: any) => {
        setCityValue(selectedOption?.value);
    };
    const addreeMutation = useMutation({
        mutationFn: SendAddress,
        onSuccess: () => {
            adrefe();
            document.getElementById('close-addrees')?.click();
        },
        onError: (error: any) => {
            const errors: any = error.response.data?.message;
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });
    const paymentMutation = useMutation({
        mutationFn: SendPayment,
        onSuccess: (data) => {
            window.open(data.paymentLink, '_self')
        },
        onError: (error: any) => {
            console.log(error)
            const errors: any = error.response.data?.message;
            toast.error(errors, toastOptions);
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });
    const onSubmit = (data: any) => {
        data.province_id = value;
        data.city_id = Cityvalue;
        addreeMutation.mutate(data)
    }
    let [selectCity, setSelect] = useState('');
    const sendToPay = () => {
        let data = {
            address_id: Number(selectCity),
        }
        paymentMutation.mutate(data)
    }
    return (
        <>
            {isLoading ? (
                <>
                    <div className="container h-100 mt-5">
                        <div className="row h-100 justify-content-center py-5 align-items-center">
                            <div className="col-md-6 text-center">
                                <img alt="logo" className="acband-price-icon animate-logo" src={require('../../assets/media/logo/final.png')} />
                                <p className="fw-bold mt-3">
                                    در حال بارگزاری..
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <section className="bg-white container">
                        <div className="row gy-4 py-5">
                            <p className="card-text fw-bold text-site">
                                سبد خرید
                            </p>
                            <div className="col-md-8 order-md-0 order-1">
                                <div className="card card-body shadow border-0">
                                    <div className="row justify-content-center gy-4">
                                        {!Cookies.get('agent_token') ? (<>
                                            {cart.map((item: any) => (
                                                <div key={`${item.product_id}-${item.variation_id}`} className="col-md-12">
                                                    <div className="card card-body border-1">
                                                        <div className="profile">
                                                            <div className="d-flex">
                                                                <div className="d-flex justify-content-center">
                                                                    <img alt="category" src={item.item_image} />
                                                                </div>
                                                                <div className="w-25">
                                                                    <p className="text-center text-split w-100 ms-2 mb-0 fw-bold font-medium-lg mt-3">
                                                                        {item.item_name}
                                                                    </p>
                                                                    <p>
                                                                        {item.item_var_name}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <span className="mt-2 fw-bold">
                                                                    {splitNumber(item.item_price)}
                                                                    <span className="ms-2">
                                                                        تومان
                                                                    </span>
                                                                </span>
                                                                <div className="d-flex">
                                                                    <div className="d-flex gap-2 align-items-center">
                                                                        <button className="btn btn-primary font-medium p-3 py-2" onClick={() => decreaseQty(item.product_id, item.variation_id)}>-</button>
                                                                        <span>{item.qty}</span>
                                                                        <button className="btn btn-primary font-medium p-3 py-2" onClick={() => increaseQty(item.product_id, item.variation_id)}>+</button>
                                                                    </div>
                                                                    <button className="btn-danger btn ms-2 font-medium p-1" onClick={() => removeFromCart(item.product_id, item.variation_id)}>
                                                                        <IconTrash />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>) : (<>
                                            {Cartmutation.isPending ? (
                                                <>
                                                    <div className="col-md-6 text-center my-3 py-3 pb-0 mb-0">
                                                        <span className="spinner-border"></span>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {carts?.cartItems?.map((item: any) => (
                                                        <div key={`${item.product_id}-${item.variation_id}`} className="col-md-12">
                                                            <div className="card card-body border-1">
                                                                <div className="profile">
                                                                    <div className="d-flex">
                                                                        <div className="d-flex justify-content-center">
                                                                            <img alt="category" src={`https://dash.akband.com/uploads/files/products/images/${item.product.primary_image}`} />
                                                                        </div>
                                                                        <div className="w-25">
                                                                            <p className="text-center text-split w-100 ms-2 mb-0 fw-bold font-medium-lg mt-3">
                                                                                {item.product.name}
                                                                            </p>
                                                                            <p>
                                                                                {item.variation.value}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <span className="mt-2 fw-bold">
                                                                            {splitNumber(item.variation.sale_price ? item.variation.sale_price : item.variation.price)}
                                                                            <span className="ms-2">
                                                                                تومان
                                                                            </span>
                                                                        </span>
                                                                        <div className="d-flex">
                                                                            <div className="d-flex gap-2 align-items-center">
                                                                                <button className="btn btn-primary font-medium p-3 py-2" onClick={() => decreaseQtyL(item.id, item.quantity)}>-</button>
                                                                                <span>{item.quantity}</span>
                                                                                <button className="btn btn-primary font-medium p-3 py-2" onClick={() => increaseQtyL(item.id, item.quantity)}>+</button>
                                                                            </div>
                                                                            <button className="btn-danger btn ms-2 font-medium p-1" onClick={() => removeFromCartL(item.id)}>
                                                                                <IconTrash />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            )}

                                        </>)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 order-md-1 order-0">
                                <div className="card card-body border-0 shadow">
                                    <div className="d-flex justify-content-center">
                                        <img alt="logo" className="acband-price-icon" src={require('../../assets/media/logo/final.png')} />
                                    </div>
                                    <ul className="list-group mt-3">
                                        <li className="list-group-item">
                                            <div className="d-flex justify-content-between">
                                                <span>قیمت :</span>
                                                <span className="text-split fw-bold">
                                                    {Cookies.get('agent_token') ? splitNumber(carts?.cartTotalAmount) : splitNumber(totalPrice)}
                                                    <span className="ms-2">
                                                        تومان
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex justify-content-between">
                                                <span>نحوه ارسال :</span>
                                                <span className="text-split">
                                                    ارسال آکبند
                                                </span>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex justify-content-between">
                                                <span>گارانتی :</span>
                                                <span className="text-split">
                                                    دارای گارانتی
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                    {Cookies.get('agent_token') ? (
                                        <button data-bs-toggle="modal" data-bs-target="#payment-modal" className="btn btn-primary mt-4">
                                            ثبت سفارش
                                        </button>
                                    ) : (
                                        <button data-bs-toggle="modal" data-bs-target="#agent" className="btn btn-primary mt-4">
                                            ثبت سفارش
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="payment-modal">
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header justify-content-between">
                                        <h6 className="mb-0 fw-bold text-site">
                                            ادامه فرایند خرید
                                        </h6>
                                        <IconX data-bs-dismiss="modal" />
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <ul className="list-group px-0">
                                                    {address?.addresses?.map((address: any) => (
                                                        <li className="list-group-item px-0">
                                                            <div className="d-flex justify-content-between border border-1 border-black rounded rounded-3 p-2">
                                                                <div className="d-flex">
                                                                    <input type="radio" onChange={(e) => setSelect(e.target.value)} name="address" value={address.id} className="form-check-input border-2 border-black" />
                                                                    <p className="mb-0 ms-1">
                                                                        {address.title}
                                                                    </p>
                                                                </div>
                                                                <p className="mb-0">
                                                                    <span className="me-2">
                                                                        آدرس:
                                                                    </span>
                                                                    {address.address}
                                                                </p>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <button data-bs-toggle="modal" data-bs-target="#address-modal" className="btn btn-outline-primary mt-2">
                                            افزودن آدرس
                                        </button>
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                        <h5 className="fw-bold mb-0">
                                            {splitNumber(carts?.cartTotalAmount)}
                                            <span className="ms-1">تومان</span>
                                        </h5>
                                        <button disabled={paymentMutation.isPending} onClick={() => sendToPay()} className="btn btn-primary">
                                            {paymentMutation.isPending ? 'در حال انتقال...' : 'انتقال به درگاه'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="address-modal">
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header justify-content-between">
                                        <h6 className="mb-0 fw-bold text-site">
                                            افزودن آدرس
                                        </h6>
                                        <IconX data-bs-dismiss="modal" />
                                    </div>
                                    <div className="modal-body">
                                        <div className="row align-items-center">
                                            <div className="col-md-12">
                                                <form onSubmit={handleSubmit(onSubmit, onError)}>
                                                    <div className="row align-items-center gy-4">
                                                        <div className="col-md-6">
                                                            <fieldset className="form-floating">
                                                                <input type="text" placeholder="عنوان" className="form-control"
                                                                    {...register('title', { required: 'فیلد عنوان الزامی است' })}
                                                                />
                                                                <label className="form-label">
                                                                    عنوان
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <fieldset className="form-floating">
                                                                <input type="tel" placeholder="شماره تماس" className="form-control"
                                                                    {...register('cellphone', { required: 'فیلد شماره تلفن الزامی است' })}
                                                                />
                                                                <label className="form-label">
                                                                    شماره تماس
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <fieldset className="form-floating">
                                                                <input type="text" placeholder="آدرس" className="form-control"
                                                                    {...register('address', { required: 'فیلد آدرس الزامی است' })}
                                                                />
                                                                <label className="form-label">
                                                                    آدرس
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <fieldset className="form-floating">
                                                                <input type="tel" placeholder="کدپستی" className="form-control"
                                                                    {...register('postal_code', { required: 'فیلد کد پستی الزامی است' })}
                                                                />
                                                                <label className="form-label">
                                                                    کدپستی
                                                                </label>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Select
                                                                styles={{
                                                                    control: (baseStyles) => ({
                                                                        ...baseStyles,
                                                                        height: 52,
                                                                        minHeight: 52,
                                                                    }),
                                                                    menuPortal: (base) => ({
                                                                        ...base,
                                                                        zIndex: 99999999999,
                                                                    }),
                                                                }}
                                                                options={provinceOptions}
                                                                onChange={onChange}
                                                                value={provinceOptions?.find((opt: { value: any; }) => opt.value === value)}
                                                                classNamePrefix="select border"
                                                                placeholder="انتخاب استان"
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Select
                                                                styles={{
                                                                    control: (baseStyles) => ({
                                                                        ...baseStyles,
                                                                        height: 52,
                                                                        minHeight: 52,
                                                                    }),
                                                                    menuPortal: (base) => ({
                                                                        ...base,
                                                                        zIndex: 99999999999,
                                                                    }),
                                                                }}
                                                                options={cities}
                                                                onChange={onChangeCity}
                                                                value={cities?.find((opt: { value: any; }) => opt.value === Cityvalue)}
                                                                classNamePrefix="select border"
                                                                placeholder="انتخاب شهر"
                                                            />
                                                        </div>
                                                    </div>
                                                    <button id="close-addrees" data-bs-toggle="modal" data-bs-target="#payment-modal" type="button" className="d-none"></button>
                                                    <button disabled={addreeMutation.isPending} className="btn btn-outline-primary mt-4">
                                                        افزودن آدرس
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )
            }
        </>
    );
};

export default CartModal;