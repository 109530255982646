// react
import React, { useRef } from "react";
// form
import { useForm } from 'react-hook-form';
import { Login } from "../../services/client/auth/login";
// react query
import { useMutation } from "@tanstack/react-query";
// toast
import { toastOptions } from "../../features/toast/toast";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @ts-ignore
import Cookies from "js-cookie";
// modal
import { useModal } from "./../../context/modalContext";
// error
import { onError } from "../../features/error/error";
// datatype
const LoginPage = () => {
    const { openModal } = useModal();
    const { register, handleSubmit }: any = useForm<any>();
    // @ts-ignore
    const button = useRef<any>()
    const mutation = useMutation({
        mutationFn: Login,
        onSuccess: data => {
            toast.success('کد پیامکی ارسال شد', toastOptions);
            Cookies.set('agent_token', data.access_token);
            localStorage.setItem('username', data.user.name)
            button.current.click();
            openModal();
        },
        onError: (error: any) => {
            const errors: any = error.response.data?.message;
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });
    const onSubmit = (data: any) => {
        mutation.mutate(data)
    };
    return (
        <>
            <section className='modal fade' id='agent'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content rounded rounded-4'>
                        <div className='modal-body'>
                            <div className='text-center w-100'>
                                <img alt='logo' className='brand-logo acband-price-icon' src={require('./../../assets/media/logo/final.png')} />
                            </div>
                            <div className="content p-4 pt-1">
                                <div className="texts text-center">
                                    <p className="h3 fw-bold text-center">ورود و ثبت نام</p>
                                    <p className="text mt-3 text-center">سلام، خوش اومدی :) </p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit, onError)}>
                                    <fieldset className='form-floating mt-2'>
                                        <input
                                            type='tel'
                                            maxLength={11}
                                            placeholder='شماره تلفن'
                                            className='form-control'
                                            {...register('cellphone', { required: 'فیلد شماره تلفن الزامی است' })}
                                        />
                                        <label className='form-label'>
                                            شماره تلفن
                                        </label>
                                    </fieldset>
                                    <button type="button" ref={button} className="d-none" hidden data-bs-toggle="modal" data-bs-target="#otp"></button>
                                    <button disabled={mutation.isPending} className='btn btn-outline-primary w-100 mt-4'>
                                        {mutation.isPending ? 'در حال ارسال...' : 'ورود / ثبت نام'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginPage;
