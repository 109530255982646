export const getAllAddresses = (routes: any[]) => {
    let addresses: string[] = [];
    routes.forEach((route) => {
        if (route.address) {
            addresses.push(route.address);
        }
        if (route.items && Array.isArray(route.items)) {
            addresses = addresses.concat(
                route.items
                    .filter((item: any) => item.address)
                    .map((item: any) => item.address)
            );
        }
    });

    return addresses;
};