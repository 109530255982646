// icon
import { IconHome, IconPhoneCalling, IconFileDescription } from "@tabler/icons-react";
// components
import Profile from "../../pages/admin/profile/profile";
import WishList from "../../pages/admin/wishList/wishlist";
import Orders from "../../pages/admin/orders/orders";
import Comments from "../../pages/admin/comments/comments";

export const RoutesUser = [
    {
        show: true,
        child: false,
        label: 'پروفایل',
        address: '/user/profile',
        component: <Profile />,
        icon: <IconHome />,
    },
    {
        show: true,
        child: false,
        label: 'علاقه مندی ها',
        address: '/user/wishList',
        component: <WishList />,
        icon: <IconPhoneCalling />,
    },
    {
        show: false,
        child: false,
        label: 'سفارشات',
        address: '/user/orders',
        component: <Orders />,
        icon: <IconFileDescription />
    },
    {
        show: false,
        child: false,
        label: 'کامنت',
        address: '/user/comments',
        component: <Comments />,
        icon: <IconFileDescription />
    },
]