// link
import { Link } from "react-router";
// style
import './style/header.css'
// routes
import { RoutesClient } from "../../features/routes/routes";
// icon
import { IconDoorExit, IconShoppingBag, IconUserCircle, IconX } from "@tabler/icons-react";
// hook
import { useEffect, useState } from "react";
// mobile nav
import HeaderNav from "../../components/headerNav/headerNav";
// controller
import useGetData from "../../hook/getData";
import { GetCategories } from "../../services/client/categories/getCategories";
// @ts-ignore
import Cookies from "js-cookie";
// controller
import { LogOut } from "../../services/client/auth/logOut";
import { useMutation } from "@tanstack/react-query";
import { useCart } from "../../context/cartContext";
import { GetCarts } from "../../services/panel/cart/getCart";

const DetailHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [parentCategory, setParentCategory] = useState<any>();
    const [childCategory, setChildCategory] = useState<any>();
    const { data: carts } = useGetData(['carts'], GetCarts)
    const toggleMenu = () => {
        setIsMenuOpen(true);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };
    const { data: categoires } = useGetData(['categories'], GetCategories)
    useEffect(() => {
        let parents = categoires?.filter((category: any) => Number(category.parent_id) === 0)
        let childs = categoires?.filter((Category: any) => Number(Category.parent_id) !== 0)
        setParentCategory(parents);
        setChildCategory(childs);
        document.getElementById('pages-header')?.classList.remove('d-none');
    }, [categoires])
    const mutation = useMutation({
        mutationFn: LogOut,
        onSuccess: () => {
            Cookies.remove('agent_token');
            window.open('/', '_self')
        },
        onError: (error: any) => {
            console.log(error)
        },
    });
    const { cart } = useCart();
    const cartCount = cart.length;
    return (
        <>
            <header className="position-sticky detail-header" onMouseMove={() => toggleMenu()} onMouseLeave={() => closeMenu()}>
                <nav className="navbar navbar-expand-md bg-light">
                    <section className="container">
                        <Link className="navbar-brand" to="#">
                            <IconX onClick={() => window.history.back()} />
                        </Link>
                        <button className="navbar-toggler" type="button">
                            {Cookies.get('agent_token') ? (
                                <>
                                    <button className="btn btn-primary p-2 mx-2 text-white">
                                        <Link to={'/user/profile'} className="text-white">
                                            <IconUserCircle size={25} />
                                        </Link>
                                    </button>
                                    <button className="btn btn-outline-primary p-2 mx-2">
                                        <IconDoorExit size={25} onClick={() => mutation.mutate()} />
                                    </button>
                                </>
                            ) : (
                                <button className="btn btn-primary mx-2 p-2" data-bs-toggle="modal" data-bs-target="#agent">
                                    ورود / ثبت نام
                                </button>
                            )}
                            <Link to={'/cart'} className="btn btn-outline-primary position-relative mx-2 p-2">
                                <IconShoppingBag size={25} />
                                <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-secondary">{carts?.cartItems?.length ? carts.cartItems.length : cartCount}</span>
                            </Link>
                        </button>
                        <div className="collapse navbar-collapse justify-content-between align-items-center" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {RoutesClient.map((address) => (
                                    <HeaderNav child={address.child} show={address.show} key={address.address} label={address.label} address={address.address} />
                                ))}
                            </ul>
                            <div className="actions py-1">
                                <div className="d-flex align-items-center gx-4">
                                    {Cookies.get('agent_token') ? (
                                        <>
                                            <button className="btn btn-primary p-2 mx-2 text-white">
                                                <Link to={'/user/profile'} className="text-white">
                                                    <IconUserCircle size={25} />
                                                </Link>
                                            </button>
                                            <button className="btn btn-outline-primary p-2 mx-2">
                                                <IconDoorExit size={25} onClick={() => mutation.mutate()} />
                                            </button>
                                        </>
                                    ) : (
                                        <button className="btn btn-primary mx-2 p-2" data-bs-toggle="modal" data-bs-target="#agent">
                                            ورود / ثبت نام
                                        </button>
                                    )}
                                    <Link to={'/cart'} className="btn btn-outline-primary position-relative mx-2 p-2">
                                        <IconShoppingBag size={25} />
                                        <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-secondary">{carts?.cartItems?.length ? carts.cartItems.length : cartCount}</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </nav>
                {isMenuOpen && (
                    <div className="container">
                        <div
                            className="collapse shadow bg-light header-collapse w-75 position-absolute container"
                            id="collapse-menu"
                            onMouseLeave={closeMenu}
                        >
                            <div className="container pt-3 pb-4">
                                <div className="accordion bg-light">
                                    <div className="row gy-3">
                                        {parentCategory?.map((category: any, index: number) => (
                                            <div className="col-lg-3 col-md-3" key={index}>
                                                <div className="accordion-item bg-light">
                                                    <h2 className="accordion-header bg-light border-0" id="headingOne">
                                                        <button className={index === 0 ? 'accordion-button bg-light border-0 fw-bold shadow-none collapsed' : 'accordion-button fw-bold bg-light border-0 shadow-none collapsed'} type="button" data-bs-toggle="collapse" data-bs-target={`#category-collapse${category.id}`} aria-expanded="true" aria-controls="collapseOne">
                                                            {category.name}
                                                        </button>
                                                    </h2>
                                                    <div id={`category-collapse${category.id}`} className={index === 0 ? 'accordion-collapse collapse' : 'accordion-collapse collapse'} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body py-0 pb-3">
                                                            <ul className="px-0 list-group py-0">
                                                                {childCategory?.map((item: any) => (
                                                                    <>
                                                                        {item.parent_id === category.id ? (
                                                                            <>
                                                                                <li className="list-group-item py-0">
                                                                                    <Link to={`/categories/${item.slug}`} className="text-decoration-none text-dark">
                                                                                        {item.name}
                                                                                    </Link>
                                                                                </li>
                                                                            </>
                                                                        ) : null}
                                                                    </>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </header>
        </>
    )
}

export default DetailHeader;