// seo
import { Route, Routes } from "react-router";
import { RoutesClient } from "./../../features/routes/routes";
import LoginPage from "./../../components/auth/auth";
import CheckOtp from "./../../components/auth/checkOtp";
// provider
import { ModalProvider } from "./../../context/modalContext";
// container
import { ToastContainer } from "react-toastify";
const ClientBase = () => {
    return (
        <>
            <main>
                <Routes>
                    {RoutesClient.map((link) => (
                        <Route key={link.address} path={link.address} element={link.component} />
                    ))}
                </Routes>
                <ModalProvider>
                    <LoginPage />
                    <CheckOtp />
                </ModalProvider>
                <ToastContainer />
            </main>
        </>
    )
}

export default ClientBase;