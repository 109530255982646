/* eslint-disable jsx-a11y/alt-text */
import "./style/detail.css"
// hook
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
// icons
import {
    IconStarFilled,
    IconListSearch,
    IconBubbleText,
    IconTruck,
    IconTextCaption,
    IconShoppingBag,
    IconShare,
    IconChartArcs,
    IconBell,
    IconCash,
    IconClock,
    IconStar,
    IconX
} from '@tabler/icons-react';
// link
import { useParams } from "react-router";
// controller
import { showProduct } from "../../services/client/products/showProduct";
import useGetData from "../../hook/getData";
// setting
import { ProductsSettings } from "../../features/swiperOptions/swiperOptions";
// component
import ProductsCard from "../../components/products/products";
// useState
import { useEffect, useState } from "react";
import { splitNumber } from "../../features/splitNumber/splitNumber";
import DetailHeader from "../../layouts/header/detailHeader";
import { FreeMode, Thumbs } from "swiper/modules";
// @ts-ignore
import Cookies from "js-cookie";
// form
import { useForm } from "react-hook-form";
import { onError } from "../../features/error/error";
import { toast } from "react-toastify";
import { toastOptions } from "../../features/toast/toast";
import { useMutation } from "@tanstack/react-query";
import { SendComment } from "../../services/panel/comments/sendComments";
import Heart from "../../components/heart/heart";
import { useCart } from "../../context/cartContext";
import { SendCart } from "../../services/panel/cart/sendCart";
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/styles.min.css'

const Detail = () => {
    const params = useParams();
    const { data, isLoading } = useGetData(['detail', params.id], showProduct);
    const [thumbsSwiper, setThumbsSwiper] = useState<any>();
    let [checkSale, setSale] = useState<any>('');
    let [price, setPrice] = useState<any>('0');
    let [day, setDay] = useState<any>('');
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedId, setId] = useState<any>('');
    const [selectedQuan, setQuan] = useState<any>('');
    const [selectedPesent, setPersent] = useState<any>('');
    const { register, handleSubmit, setValue, watch }: any = useForm<any>();
    const [hovered, setHovered] = useState<number | null>(null);
    const selectedRate = watch('rate', 0);
    useEffect(() => {
        window.scrollTo(0, 0);
        setSale(data?.productVariations[0]?.is_sale)
        setPersent(data?.productVariations[0]?.percent_sale)
        setQuan(data?.productVariations[0]?.quantity)
        setId(data?.productVariations[0]?.id)
        setPrice(data?.productVariations[0]?.sale_price ? data?.productVariations[0]?.sale_price : data?.productVariations[0]?.price)
        const dateFrom = new Date(data?.productVariations[0]?.date_on_sale_from);
        const dateTo = new Date(data?.productVariations[0]?.date_on_sale_to);
        const diffMs = dateTo.getTime() - dateFrom.getTime();
        const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));
        setDay(diffDays)
        setSelectedValue(data?.productVariations[0]?.value);
        document.getElementById('pages-header')?.classList.add('d-none');
    }, [data?.productVariations])
    const filterSale = (price: any, sale: any, from: any, to: any, value: any, valueId: any, quan: any, persent: any) => {
        setSale(sale)
        setPrice(price)
        setSelectedValue(value)
        const dateFrom = new Date(from);
        const dateTo = new Date(to);
        const diffMs = dateTo.getTime() - dateFrom.getTime();
        const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));
        setDay(diffDays)
        setId(valueId)
        setQuan(quan)
        setPersent(persent)
    }
    const mutation = useMutation({
        mutationFn: SendComment,
        onSuccess: () => {
            toast.success('نظر شما ثبت شد', toastOptions);
        },
        onError: (error: any) => {
            const errors: any = error.response.data?.message;
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });
    const onSubmit = (items: any) => {
        let newData = items;
        newData.id = data?.product?.id;
        mutation.mutate(newData)
    };
    const shareProduct = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: document.title,
                    text: data.product.title,
                    url: window.location.href,
                });
            } catch (error) {
                console.error('Error sharing content:', error);
            }
        } else {
            console.error('Error sharing content:');
        }
    };
    const { addToCart } = useCart();
    const Cartmutation = useMutation({
        mutationFn: SendCart,
        onSuccess: () => {
            window.open('/cart', '_self')
        },
        onError: (error: any) => {
            const errors: any = error.response.data?.message;
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });
    const addToCarts = (product_id: number, qtybutton: number, variation_id: number, quantity: number) => {
        if (Cookies.get('agent_token')) {
            let data = {
                items: [
                    {
                        product_id: product_id,
                        variation_id: variation_id,
                        quantity: 1,
                    }
                ]
            }
            Cartmutation.mutate(data);
        }
        else {
            addToCart({
                product_id: product_id,
                variation_id: variation_id,
                qty: 1,
                quantity: quantity,
                item_image: data?.product?.primary_image,
                item_name: data?.product?.name,
                item_price: price,
                item_var_name: selectedValue,
            });
        }
        toast.success('با موفقیت به سبد اضافه شد', toastOptions)
    }
    return (
        <>
            <DetailHeader />
            {isLoading ? (
                <>
                    <div className="container h-100 mt-5">
                        <div className="row h-100 justify-content-center py-5 align-items-center">
                            <div className="col-md-6 text-center">
                                <img className="acband-price-icon animate-logo" src={require('../../assets/media/logo/final.png')} />
                                <p className="fw-bold mt-3">
                                    در حال بارگزاری..
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <section className="bg-white app project-app">
                        <div className="container">
                            <div className="row overflow-x-hidden">
                                <div className="col-lg-5 col-md-6 col-12 mt-5 position-relative">
                                    <div className="product-icons top-25 position-absolute">
                                        <ul className="list-group">
                                            <li className="list-group-item px-2">
                                                <Heart id={data?.product?.id} />
                                            </li>
                                            <li className="list-group-item px-2" onClick={() => shareProduct()}><IconShare /></li>
                                            <li className="list-group-item px-2"><IconBell /></li>
                                        </ul>
                                    </div>
                                    {data?.productVariations.length > 0 ? (
                                        <>
                                            {checkSale ? (
                                                <div className="offer-detail-suggest px-4 d-flex align-items-center justify-content-between">
                                                    <p className="offer-detail-suggest-child mb-2">شگفتانه</p>
                                                    <p className="offer-detail-suggest-child2 mb-2">
                                                        <IconClock size={14} className="me-1" />
                                                        {day}
                                                        روز
                                                    </p>
                                                </div>
                                            ) : (
                                                <div style={{ backgroundColor: '#BBDEFB', color: 'black' }} className="offer-detail-suggest px-4 d-flex align-items-center justify-content-between">
                                                    <p className="offer-detail-suggest-child mb-2">موجود</p>
                                                    <p className="offer-detail-suggest-child2 mb-2">
                                                        آکبند
                                                    </p>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div style={{ backgroundColor: '#FFCDD2', color: 'black' }} className="offer-detail-suggest px-4 d-flex align-items-center justify-content-between">
                                            <p className="offer-detail-suggest-child mb-2">ناموجود</p>
                                            <p className="offer-detail-suggest-child2 mb-2">آکبند</p>
                                        </div>
                                    )}
                                    <div className="product-img mt-2" data-aos="fade-right">
                                        <div className="img-detail-main d-flex align-items-center justify-content-center">
                                            {data?.product?.images ? (
                                                <Swiper
                                                    loop={true}
                                                    spaceBetween={10}
                                                    navigation={true}
                                                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                                                    modules={[FreeMode, Thumbs]}
                                                    className="mySwiper2"
                                                >
                                                    {data?.product?.images?.map((image: any, index: number) => (
                                                        <>
                                                            <SwiperSlide key={index + 'sliderTop'}>
                                                                <InnerImageZoom
                                                                    src={image.image}
                                                                    zoomSrc={image.image}
                                                                    width={750}
                                                                    height={400}
                                                                    zoomType="hover"
                                                                    zoomPreload={true}
                                                                    hasSpacer={true}
                                                                    fullscreenOnMobile={true}
                                                                    className="project-banner-img"
                                                                />
                                                            </SwiperSlide>
                                                        </>
                                                    ))}
                                                </Swiper>
                                            ) : null}
                                        </div>
                                        {data?.product?.images ? (
                                            <Swiper
                                                onSwiper={setThumbsSwiper}
                                                loop={true}
                                                spaceBetween={10}
                                                slidesPerView={4}
                                                freeMode={true}
                                                watchSlidesProgress={true}
                                                modules={[FreeMode, Thumbs]}
                                                className="mySwiper mt-3"
                                            >
                                                {data?.product?.images?.map((image: any, index: number) => (
                                                    <>
                                                        <SwiperSlide key={index + 'sliderTop'}>
                                                            <img className="img-detail-far-img img-fluid p-2"
                                                                src={image.image} alt="product" />
                                                        </SwiperSlide>
                                                    </>
                                                ))}
                                            </Swiper>
                                        ) : null}
                                    </div>
                                </div>
                                <div id="star-box" className="col-lg-7 col-md-6 col-12 mt-5">
                                    <p className="detail-product-name text-split mb-0 mt-1">
                                        {data?.product?.name}
                                    </p>
                                    <div className="d-md-flex d-none align-items-center justify-content-between mt-2">
                                        <p className="text-muted font-medium-lg mb-0">
                                            امتیاز محصول در آکبند
                                        </p>
                                        <div className="ltr">
                                            <div className="mt-2 text-site fw-bold font-medium-lg">
                                                فروش در آکبند
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mt-md-2 mt-3 align-items-center">
                                        <div className="d-flex align-content-center me-2">
                                            <IconStarFilled color="#F9AE2E" size={12} />
                                            <p className="font-medium ms-1 mb-0">
                                                {data?.product?.average_rate}
                                            </p>
                                        </div>
                                        {data?.product?.tags?.map((item: any) => (
                                            <i className="mx-1 font-medium text-2 rounded rounded-3">
                                                {item}
                                            </i>
                                        ))}
                                    </div>
                                    <div className="row overflow-x-hidden gy-5 mt-md-3 mt-1">
                                        <div className="col-md-6 mt-4">
                                            <div className="detail-color fw-bold">
                                                <p>{data?.productVariations ? data?.productVariations[0]?.attribute?.name : ''} : {selectedValue}</p>
                                                <div className="d-flex">
                                                    {data?.productVariations?.map((item: any) => (
                                                        <div>
                                                            <input checked={item.value === selectedValue} type="radio" onChange={() => filterSale(item.sale_price ? item.sale_price : item.price, item.is_sale, item.date_on_sale_from, item.date_on_sale_to, item.value, item.id, item.quantity, item.percent_sale)} value={item.value} className="mx-1" name="radio" />
                                                            <p className="mb-0 font-small-lg text-center">{item.value}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="mt-4 fw-bold">
                                                <div className="row gy-3">
                                                    {data?.productAttributes?.map((item: any, index: number) => (
                                                        <>
                                                            {index < 4 ? (
                                                                <div className="col-6">
                                                                    <div className="topic-detail-text-parent w-100 p-3">
                                                                        <p className="topic-detail-text mb-0">
                                                                            {item.attribute.name}
                                                                        </p>
                                                                        <p className="topic-detail-text-child text-split fw-bold mb-0">
                                                                            {item.value}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card card-body border-1">
                                                <div className="d-flex justify-content-center">
                                                    <img className="acband-price-icon" src={require('../../assets/media/logo/final.png')} />
                                                </div>
                                                <div className="d-flex justify-content-between align-content-end">
                                                    <p className="price-item-name text-split mt-2 ms-2 pe-3">{data?.product?.name}</p>
                                                    <p className="price-item-name2 mt-2 me-2">آکبند</p>
                                                </div>
                                                <div className="d-flex justify-content-between align-content-end mt-2 pt-2 border-top">
                                                    <IconShoppingBag color={"#6C7275"} className="ms-2" />
                                                    <p className="me-2">
                                                        <span className="fw-bold me-1">
                                                            {splitNumber(price)}
                                                        </span>
                                                        تومان
                                                        {selectedPesent && <span className="text-danger badge bg-danger text-light ms-3 fw-bold mb-0">{selectedPesent}%</span>}
                                                    </p>
                                                </div>
                                                <div className="d-grid">
                                                    <button disabled={data?.productVariations.length <= 0} onClick={() => addToCarts(data?.product?.id, 1, selectedId, selectedQuan)} className="btn btn-primary">افزودن به سبد خرید</button>
                                                </div>
                                                <p className="price-description ms-2">دارای گارانتی</p>
                                                <div className="d-flex pt-3 border-top">
                                                    <IconTruck size={"16px"} color={"red"} />
                                                    <p className={"delivery-price ms-2 mb-0"}>ارسال آکبند</p>
                                                </div>
                                                <div className="d-flex mt-2">
                                                    <IconTextCaption size={"16px"} color={"blue"} />
                                                    <p className={"delivery-price ms-2 mb-0"}>تحویل درب منزل</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="bg-white py-5 pb-3">
                            <div className="container">
                                <img src={require('./../../assets/media/banner/bannerEnd.webp')} alt="banner" className="img-fluid banner-small rounded rounded-4 w-100" />
                            </div>
                        </section>
                        <div id="description" className="container mt-3">
                            <div className="row overflow-x-hidden">
                                <div className="col-12 col-md-6">
                                    <div className="collapse collapse-des-short show">
                                        <div className="detail-text-info" >
                                            <div className="d-flex w-100 justify-content-center align-content-center">
                                                <div className="line-detail"></div>
                                                <p className="detail-text-info-p text-center mt-3">توضیحات محصول</p>
                                                <div className="line-detail"></div>
                                            </div>
                                            <p className="detail-text-info-child text-line line-30 card-text mb-1">
                                                {data?.product?.description}
                                            </p>
                                            <a href={'.collapse-des-short'} data-bs-toggle="collapse" className={"info-t mb-2 font-medium-lg line-30 card-text text-end text-site text-decoration-none d-block"}>
                                                جزعیات...
                                            </a>
                                        </div>
                                    </div>
                                    <div className="collapse collapse-des-short">
                                        <div className="detail-text-info" >
                                            <div className="d-flex w-100 justify-content-center align-content-center">
                                                <div className="line-detail"></div>
                                                <p className="detail-text-info-p text-center mt-3">توضیحات محصول</p>
                                                <div className="line-detail"></div>
                                            </div>
                                            <p className="detail-text-info-child line-30 card-text mb-1">
                                                {data?.product?.description}
                                            </p>
                                            <a href={'.collapse-des-short'} data-bs-toggle="collapse" className={"info-t mb-2 font-medium-lg line-30 card-text text-end text-site text-decoration-none d-block"}>
                                                بستن...
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" id="ability-part">
                                    <div className="row overflow-x-hidden g-3 mt-2">
                                        {data?.productAttributes?.map((item: any) => (
                                            <div className="col-6 col-md-3">
                                                <div className="topic-detail-text-parent w-100 p-3">
                                                    <p className="topic-detail-text mb-0">
                                                        {item.attribute.name}
                                                    </p>
                                                    <p className="topic-detail-text-child text-split fw-bold mb-0">
                                                        {item.value}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="detail-list-info mt-5 w-100 container">
                            <div className="row overflow-x-hidden">
                                <div className="col-md-3 col-6">
                                    <div className="list-detail-parA-parent d-flex justify-content-center">
                                        <IconListSearch className="list-detail-iA" />
                                        <p className="list-detail-parA">بخش بندی محصول :</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6">
                                    <a href="#ability-part" className="text-decoration-none">
                                        <div className="d-flex justify-content-center">
                                            <p className="list-detail-par">امکانات محصول</p>
                                            <IconChartArcs className="list-detail-i" />
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-3 col-6">
                                    <a href="#star-box" className="text-decoration-none">
                                        <div className="d-flex justify-content-center">
                                            <p className="list-detail-par">ویژگی های محصول</p>
                                            <IconCash className="list-detail-i" />
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="d-flex justify-content-center">
                                        <p className="list-detail-par">نظرات </p>
                                        <IconBubbleText className="list-detail-i" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mt-3 pt-3">
                            <div className="detail-attribute">
                                <p className="detail-attribute-p">نظرات کاربران</p>
                            </div>
                            <div className="row">
                                {data?.comments?.map((item: any) => (
                                    <>
                                        <div className="col-md-4">
                                            <div className="card card-body border-1">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="card-title fw-bold">
                                                        {item.user.name}
                                                    </p>
                                                    <div className="d-flex align-content-center align-items-center">
                                                        <IconStarFilled color="#F9AE2E" size={12} />
                                                        <p className="font-medium ms-1 mt-1 mb-0">
                                                            {item?.rate?.rate}
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className="card-text fw-bold font-medium">
                                                    {item.text}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                            {Cookies.get('agent_token') ? (
                                <>
                                    <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#comment-modal">
                                        ثبت نظر
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button data-bs-toggle="modal" data-bs-target="#agent" className="btn btn-primary">
                                        ثبت نظر
                                    </button>
                                </>
                            )}
                        </div>
                        <section className="bg-white py-5 pb-3">
                            <div className="container">
                                <div className="row gy-4">
                                    <div className="col-md-6 mt-3">
                                        <img src={require('./../../assets/media/banner/banner_top_1.webp')} alt="banner" className="img-fluid rounded rounded-4" />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <img src={require('./../../assets/media/banner/banner_top_2.webp')} alt="banner" className="img-fluid rounded rounded-4" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="d-flex ablity-icons w-100 justify-content-center align-items-center mt-3">
                            <div className="container flex-wrap d-flex justify-content-center align-items-center shadow-sm">
                                <div className="col-6 col-sm-3 text-center">
                                    <img src={require('./../../assets/media/icon/cash-on-delivery 1.png')} />
                                    <p className="font-medium-lg">پرداخت در محل</p>
                                </div>
                                <div className="col-6 col-sm-3 text-center">
                                    <img src={require('./../../assets/media/icon/support 1.png')} />
                                    <p className="font-medium-lg">پشتیبانی سریع</p>
                                </div>
                                <div className="col-6 col-sm-3 text-center">
                                    <img src={require('./../../assets/media/icon/days-return 1.png')} />
                                    <p className="font-medium-lg">ضمانت بازگشت کالا</p>
                                </div>
                                <div className="col-6 col-sm-3 text-center">
                                    <img src={require('./../../assets/media/icon/original-products 1.png')} />
                                    <p className="font-medium-lg">ضمانت اصل بودن کالا</p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white py-5 pb-3">
                            <div className="container">
                                <div className="d-flex justify-content-between mb-3 align-items-center">
                                    <h6 className="fw-bold h5 mb-0">محصولات مشابه</h6>
                                </div>
                                <div className="new-products">
                                    {data?.sameProducts ? (
                                        <Swiper
                                            {...ProductsSettings}
                                        >
                                            {data?.sameProducts?.map((product: any) => (
                                                <SwiperSlide key={product.id + 'products'}>
                                                    <ProductsCard id={product.id} slug={product.slug} name={product.name} primary_image={product.primary_image} description={product.description} quantity_check={product.quantity_check} />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </section >
                    <article className="end-banner">
                        <img src={require('./../../assets/media/banner/WATER-COOLING-SLIDER2 1.webp')} className="img-fluid banner-small w-100" alt="banner" />
                    </article>
                    <div className="modal fade" id="comment-modal">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header justify-content-between">
                                    <h6 className="text-site fw-bold mb-0">کامنت</h6>
                                    <IconX data-bs-dismiss="modal" />
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="text-site">
                                                امتیاز شما :
                                            </span>
                                            <div className="d-flex">
                                                {[1, 2, 3, 4, 5].map((star) => {
                                                    const isFilled = hovered ? star <= hovered : star <= selectedRate;
                                                    const Icon = isFilled ? IconStarFilled : IconStar;
                                                    return (
                                                        <button
                                                            type="button"
                                                            key={star}
                                                            onClick={() => setValue('rate', star)}
                                                            onMouseEnter={() => setHovered(star)}
                                                            onMouseLeave={() => setHovered(null)}
                                                            className="btn-white font-medium text-warning btn px-1"
                                                        >
                                                            <Icon size={20} />
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <fieldset className="form-floating mt-3">
                                            <textarea className="form-control" placeholder="نظر خود را بنویسید"
                                                {...register('text', { required: 'فیلد کامنت الزامی است' })}
                                            ></textarea>
                                            <label className="form-label">نظر شما</label>
                                        </fieldset>
                                        <input type="hidden" {...register('rate', { required: 'امتیاز دادن الزامی است' })} />
                                        <button disabled={mutation.isPending} className="btn btn-primary mt-3">
                                            {mutation.isPending ? 'درحال ارسال ...' : 'ثبت نظر'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

        </>
    )
}
export default Detail;