// controller
import { splitNumber } from "../../../features/splitNumber/splitNumber";
import useGetData from "../../../hook/getData";
import { GetOrders } from "../../../services/panel/orders/getOrders";

const Orders = () => {
    const { data: orders } = useGetData(['orders'], GetOrders)
    return (
        <>
            <h6 className="fw-bold mb-0">سفارشات</h6>
            <hr />
            <div className="table-responsive">
                <table className="table table-bordered text-center font-medium align-middle">
                    <thead>
                        <tr>
                            <th>ردیف</th>
                            <th>توضیحات</th>
                            <th>محصولات</th>
                            <th>هزینه پیک</th>
                            <th>هزینه کل</th>
                            <th>وضعیت</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders?.orders.map((order: any, index: number) => (
                            <tr key={index + 'order'}>
                                <td>
                                    {index + 1}
                                </td>
                                <td>
                                    {order.decoration ? order.decoration : 'فاقد توضیحات'}
                                </td>
                                <td>
                                    {order?.items?.map((item: any) => (
                                        <p className="text-start">
                                            {item.quantity}
                                            عدد
                                            {item.product.name}
                                        </p>
                                    ))}
                                </td>
                                <td>
                                    {splitNumber(order.delivery_amount)}
                                </td>
                                <td>
                                    {splitNumber(order.total_amount)}
                                </td>
                                <td className="fw-bold">
                                    <button className="btn btn-primary font-medium">
                                        {order.status}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Orders;
