// icon
import { IconHome, IconPencil, IconPhoneCalling, IconShoppingBag, IconFileDescription } from "@tabler/icons-react";
// components
import Home from "../../pages/home/home";
import Detail from "../../pages/detail/Detail";
import ContactUs from "../../pages/contactUs/contactUs";
import AboutUs from "../../pages/aboutUs/aboutUs";
import Products from "../../pages/products/products";
import CartModal from "../../components/cart/cartModal";

export const RoutesClient = [
    {
        show: true,
        child: false,
        label: 'خانه',
        address: '/',
        component: <Home />,
        icon: <IconHome />,
    },
    {
        show: true,
        child: true,
        label: 'دسته بندی',
        address: '#collapse-menu',
        mobaddress: '#modal-menu',
        component: <Products />,
        icon: <IconShoppingBag />,
    },
    {
        show: true,
        child: false,
        label: 'درباره ما',
        address: '/aboutUs',
        component: <AboutUs />,
        icon: <IconPencil />,
    },
    {
        show: true,
        child: false,
        label: 'ارتباط باما',
        address: '/contactUs',
        component: <ContactUs />,
        icon: <IconPhoneCalling />,
    },
    {
        show: false,
        child: false,
        label: 'توضیحات محصول',
        address: '/products/detail/:id',
        component: <Detail />,
        icon: <IconFileDescription />
    },
    {
        show: false,
        child: false,
        label: 'سبد خرید',
        address: '/cart',
        component: <CartModal />,
        icon: <IconFileDescription />
    },
    {
        show: false,
        child: false,
        label: ' بر اساس دسته بندی',
        address: '/categories/:slug',
        component: <Products />,
        icon: <IconFileDescription />
    }
]