// icon
import { IconShoppingBag, IconStar } from "@tabler/icons-react";
// link
import { Link } from "react-router";
// splitnumber
import { splitNumber } from "../../features/splitNumber/splitNumber";
// heart
import Heart from "../heart/heart";

const NewProducts = (props: any) => {
    return (
        <>
            <div data-aos="fade-right"
                className="card new-product-card category-card border-0">
                <div className="card-image rounded rounded-4 p-3">
                    <span style={{ zIndex: '99999999' }} className="badge bg-white text-dark icon">جدید</span>
                    <div className="d-flex justify-content-center">
                        <Link to={`/products/detail/${props.slug}`} className="text-decoration-none">
                            <img src={props.primary_image} alt="product" />
                        </Link>
                    </div>
                    <div className="icon like-place shadow">
                        <Heart id={props.id} />
                    </div>
                    <Link to={`/products/detail/${props.slug}`} className="btn btn-primary w-100 py-1 mt-3">
                        <IconShoppingBag size={16} className="align-middle me-2" />
                        خرید
                    </Link>
                </div>
                <div className="card-body mt-1">
                    <Link to={`/products/detail/${props.slug}`} className="text-decoration-none">
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <span className="text-dark w-50 text-split fw-bold font-medium-lg mb-0">
                                {props.name}
                            </span>
                            <div className="stars w-50 text-end text-warning font-medium-lg align-middle">
                                <IconStar size={14} />
                                <IconStar size={14} />
                                <IconStar size={14} />
                                <IconStar size={14} />
                            </div>
                        </div>
                        <p className="card-text text-split text-black font-medium mb-0 mt-2">
                            {props.description}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <IconShoppingBag className="text-site" />
                            {props.quantity_check?.price ? (
                                <span className="card-title price font-medium-lg">{splitNumber(props.quantity_check?.price)}  تومان</span>
                            ) : (
                                <span className="card-title text-danger fw-bold price font-medium-lg">نا موجود</span>
                            )}
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default NewProducts;