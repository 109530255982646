const Step = (e: React.KeyboardEvent<HTMLInputElement>, index: number, elm: string) => {
    // @ts-ignore
    const value = e.target?.value;
    let items = document.getElementsByClassName('step-input');
    if (e.key != 'Backspace') {
        // @ts-ignore
        if (value.length === e.target?.maxLength) {
            if (document.getElementById(`${elm}${index}`)) {
                // @ts-ignore
                document.getElementById(`${elm}${index - 1}`)?.focus();
            }
        }
        for (let i = 0; i < items.length; i++) {
            // @ts-ignore
            items[i].disabled = false;
        }
    } else {
        // @ts-ignore
        if (value.length === 0) {
            document.getElementById(`${elm}${index + 1}`)?.focus();
        }
    }
}

export default Step;