// controller
import { toast } from "react-toastify";
import useGetData from "../../../hook/getData";
import { GetWishList } from "../../../services/panel/wishList/wishList";
import { toastOptions } from "../../../features/toast/toast";
import { useMutation } from "@tanstack/react-query";
import { RemoveWish } from "../../../services/panel/wishList/deleteWishList";
import { Link } from "react-router";

const WishList = () => {
    const { data: list, refetch } = useGetData(["wishList"], GetWishList);
    const Remutation = useMutation({
        mutationFn: RemoveWish,
        onSuccess: () => {
            toast.success("با موفقیت حذف شد", toastOptions);
            refetch();
        },
        onError: (error: any) => {
            const errors: any = error.response?.data?.message;
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });
    const removeToList = (id: any) => {
        Remutation.mutate(id);
    };

    return (
        <>
            <h6 className="fw-bold mb-0">علاقه مندی ها</h6>
            <hr />
            <div className="table-responsive">
                <table className="table table-bordered text-center font-medium align-middle">
                    <thead>
                        <tr>
                            <th>ردیف</th>
                            <th>بر روی محصول</th>
                            <th>وضعیت</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((comment: any, index: number) => (
                            <tr key={index + 'order'}>
                                <td>
                                    {index + 1}
                                </td>
                                <td>
                                    <Link to={`/products/detail/${comment.product?.slug}`} className="text-decoration-none">
                                        {comment.product?.name}
                                    </Link>
                                </td>
                                <td className="fw-bold">
                                    <button onClick={() => removeToList(comment.product?.id)} className="btn btn-primary font-medium">
                                        حذف از لیست
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default WishList;