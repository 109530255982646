import { IconHeart, IconHeartFilled } from "@tabler/icons-react";
// @ts-ignore
import Cookies from "js-cookie";
// controller
import { GetWishList } from "../../services/panel/wishList/wishList";
import useGetData from "../../hook/getData";
import { SendWish } from "../../services/panel/wishList/sendWishList";
import { useMutation } from "@tanstack/react-query";
import { RemoveWish } from "../../services/panel/wishList/deleteWishList";
// toast
import { toast } from "react-toastify";
import { toastOptions } from "../../features/toast/toast";

const Heart = (props: any) => {
    const token = Cookies.get("agent_token");

    const { data: list, refetch } = useGetData(
        token ? ["wishList"] : [],
        token ? GetWishList : () => Promise.resolve([])
    );

    const mutation = useMutation({
        mutationFn: SendWish,
        onSuccess: () => {
            toast.success("با موفقیت اضافه شد", toastOptions);
            refetch();
        },
        onError: (error: any) => {
            const errors: any = error.response?.data?.message;
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });
    const Remutation = useMutation({
        mutationFn: RemoveWish,
        onSuccess: () => {
            toast.success("با موفقیت حذف شد", toastOptions);
            refetch();
        },
        onError: (error: any) => {
            const errors: any = error.response?.data?.message;
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });

    const addToList = (id: any) => {
        if (!token) {
            toast.error("لطفاً ابتدا وارد شوید", toastOptions);
            return;
        }
        mutation.mutate(id);
    };

    const removeToList = (id: any) => {
        if (!token) {
            toast.error("لطفاً ابتدا وارد شوید", toastOptions);
            return;
        }
        Remutation.mutate(id);
    };

    const isInWishlist = list?.some((item: any) => item.product.id === props.id);

    return (
        <>
            {isInWishlist ? (
                <>
                    {Remutation.isPending ? (
                        <>
                            <span className="spinner-border font-medium text-muted" style={{ width: '20px', height: '20px' }}></span>
                        </>
                    ) : (
                        <>
                            <IconHeartFilled
                                onClick={() => removeToList(props.id)}
                                className="text-danger cursor-pointer"
                                size={18}
                            />
                        </>
                    )}
                </>
            ) : (
                <>
                    {mutation.isPending ? (
                        <>
                            <span className="spinner-border font-small text-muted" style={{ width: '20px', height: '20px' }}></span>
                        </>
                    ) : (
                        <>
                            <IconHeart
                                onClick={() => addToList(props.id)}
                                className="text-gray-400 cursor-pointer hover:text-red-500 transition"
                                size={18}
                            />
                        </>
                    )}
                </>

            )}
        </>
    );
};

export default Heart;
