// style
import './style/style.css'
// hook and controller
import useGetData from '../../hook/getData';
import { GetSettings } from '../../services/client/settings/getSettings';
import { useEffect } from 'react';
const AboutUs = () => {
    const { data: settings } = useGetData(['settings'], GetSettings)
    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById('pages-header')?.classList.remove('d-none');
    }, [])
    return (
        <>
            <section className="contactus bg-white py-5">
                <div className="container">
                    <div className="row gy-4 overflow-hidden align-items-center">
                        <div className="col-md-5 text-start" data-aos="fade-up">
                            <img src={require('./../../assets/media/banner/aboutUs.jpg')} alt="about" className="about-img" />
                        </div>
                        <div className="col-md-7" data-aos="fade-right">
                            <h1 className='fw-bold card-title h4'>درباره ما</h1>
                            {settings?.data ? (settings.data) : (
                                <>
                                    <p className='mt-3 line-30 card-text'>
                                        آکبند با هدف ارائه بهترین و به‌روزترین تجهیزات کامپیوتر و لپ‌تاپ، فعالیت خود را آغاز کرده است. ما به عنوان یکی از تخصصی‌ترین فروشگاه‌های آنلاین، تلاش می‌کنیم تا با ارائه محصولاتی با کیفیت بالا و قیمت مناسب، تجربه‌ای متفاوت از خرید را برای مشتریان خود رقم بزنیم.
                                        در آکبند، تمامی کالاها به‌صورت آکبند و پلمپ‌شده ارائه می‌شوند تا خیال مشتریان از اصالت و کیفیت محصولات راحت باشد. ما مجموعه‌ای از لپ‌تاپ‌های گیمینگ، مهندسی، دانشجویی، قطعات کامپیوتر، لوازم جانبی و تجهیزات شبکه را با دقت انتخاب کرده و در اختیار مشتریان قرار می‌دهیم.
                                    </p>
                                    <ul className='list-group px-0'>
                                        <li className='list-group-item px-0'>
                                            ✔ ضمانت اصالت کالا – تمامی محصولات ما کاملاً نو، اورجینال و دارای گارانتی معتبر هستند.
                                        </li>
                                        <li className='list-group-item px-0'>
                                            ✔ تنوع بالا و قیمت رقابتی – تلاش ما ارائه محصولات با بهترین قیمت و تنوع گسترده است.
                                        </li>
                                        <li className='list-group-item px-0'>
                                            ✔ پشتیبانی تخصصی – تیم پشتیبانی ما همیشه آماده پاسخگویی و راهنمایی به مشتریان است.
                                        </li>
                                    </ul>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <article className="end-banner">
                <img src={require('./../../assets/media/banner/casemod1 1.png')} className="img-fluid banner-small w-100" alt="banner" />
            </article>
        </>
    )
}

export default AboutUs;