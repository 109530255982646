import { createContext, useContext, useEffect, useState } from "react";

type CartItem = {
    product_id: number;
    variation_id: number;
    qty: number;
    quantity: number;
    item_image: any;
    item_name: any;
    item_price: any;
    item_var_name: any;
};

type CartContextType = {
    cart: CartItem[];
    addToCart: (item: CartItem) => void;
    increaseQty: (product_id: number, variation_id: number) => void;
    decreaseQty: (product_id: number, variation_id: number) => void;
    removeFromCart: (product_id: number, variation_id: number) => void;
};

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider = ({ children }: any) => {
    const [cart, setCart] = useState<CartItem[]>(() => {
        const stored = localStorage.getItem("cart");
        return stored ? JSON.parse(stored) : [];
    });

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart]);

    const addToCart = (newItem: CartItem) => {
        const qtyToAdd = Number(newItem.qty);

        setCart(prev => {
            const existingItem = prev.find(
                item =>
                    item.product_id === newItem.product_id &&
                    item.variation_id === newItem.variation_id
            );

            if (existingItem) {
                const newQty = existingItem.qty + qtyToAdd;
                if (newQty > existingItem.quantity) return prev;

                return prev.map(item =>
                    item.product_id === newItem.product_id &&
                        item.variation_id === newItem.variation_id
                        ? { ...item, qty: newQty }
                        : item
                );
            } else {
                return qtyToAdd <= newItem.quantity
                    ? [...prev, { ...newItem, qty: qtyToAdd }]
                    : prev;
            }
        });
    };

    const increaseQty = (product_id: number, variation_id: number) => {
        setCart(prev =>
            prev.map(item => {
                if (
                    item.product_id === product_id &&
                    item.variation_id === variation_id
                ) {
                    if (item.qty < item.quantity) {
                        return { ...item, qty: item.qty + 1 };
                    }
                }
                return item;
            })
        );
    };

    const decreaseQty = (product_id: number, variation_id: number) => {
        setCart(prev =>
            prev
                .map(item =>
                    item.product_id === product_id && item.variation_id === variation_id
                        ? { ...item, qty: item.qty - 1 }
                        : item
                )
                .filter(item => item.qty > 0)
        );
    };

    const removeFromCart = (product_id: number, variation_id: number) => {
        setCart(prev =>
            prev.filter(item => !(item.product_id === product_id && item.variation_id === variation_id))
        );
    };

    return (
        <CartContext.Provider
            value={{
                cart,
                addToCart,
                increaseQty,
                decreaseQty,
                removeFromCart
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error("useCart must be used within a CartProvider");
    }
    return context;
};
