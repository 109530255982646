import { IconCircleChevronLeft } from "@tabler/icons-react";
import { NavLink } from "react-router";
// @ts-ignore
import Cookies from "js-cookie";
// controllers
import { LogOut } from "../../services/client/auth/logOut";
import { useMutation } from "@tanstack/react-query";
const Menu = (props: any) => {
    const mutation = useMutation({
        mutationFn: LogOut,
        onSuccess: () => {
            Cookies.remove('agent_token');
            window.open('/', '_self')
        },
        onError: (error: any) => {
            console.log(error)
        },
    });
    return (
        <>
            <section className="card card-body border-0 shadow">
                <div className="profile">
                    <div className="d-flex">
                        <div className="d-flex justify-content-center">
                            <img alt="category" src={localStorage.getItem('userImage') ? localStorage.getItem('userImage') : require('./../../assets/media/logo/final.png')} />
                        </div>
                        <p className="text-center ms-2 mb-0 fw-bold font-medium-lg mt-3">
                            {localStorage.getItem('username') ? localStorage.getItem('username') : 'کاربر'}
                        </p>
                    </div>
                </div>
                <hr />
                <ul className="list-group px-0">
                    {props.routes?.map((link: any) => (
                        <li className="px-0 list-group-item py-2">
                            <NavLink to={link.address} className={'text-decoration-none text-black'}>
                                <div className="d-flex align-items-center justify-content-between">
                                    {link.label}
                                    <IconCircleChevronLeft />
                                </div>
                            </NavLink>
                        </li>
                    ))}
                    <li className="px-0 list-group-item py-2">
                        <hr />
                        <span onClick={() => mutation.mutate()} className="text-danger fw-bold">
                            خروج از حساب
                        </span>
                    </li>
                </ul>
            </section>
        </>
    )
}

export default Menu;