// axios
import axios from "axios";
// @ts-ignore
import Cookies from "js-cookie";

export const GetOrders = async () => {
    let token = Cookies.get('agent_token');
    let ClientOption: any = {
        headers: {
            'APIKEY': process.env.REACT_APP_API_CLIENT_KEY,
            Authorization: `Bearer ${token}`,
        }
    };
    const { data: response } = await axios.get(`${process.env.REACT_APP_API_URL}user/orders`, ClientOption);
    return response.data;
}