// axios
import axios from "axios";
// @ts-ignore
import Cookies from "js-cookie";

export const SendComment = async (data: any) => {
    let id = data.id
    let newData = data;
    delete newData.id;
    let token = Cookies.get('agent_token');
    let ClientOption: any = {
        headers: {
            'APIKEY': process.env.REACT_APP_API_CLIENT_KEY,
            Authorization: `Bearer ${token}`,
        }
    };
    const { data: response } = await axios.post(`${process.env.REACT_APP_API_URL}user/comments/${id}`, newData, ClientOption);
    return response.data;
}