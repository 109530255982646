// seo
import { Route, Routes, useNavigate } from "react-router";
// container
import { ToastContainer } from "react-toastify";
import { RoutesUser } from "../../features/routes/userRouts";
// component
import Menu from "../menu/menu";
// @ts-ignore
import Cookies from "js-cookie";
// hook
import { useEffect } from "react";
const AdminBase = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!Cookies.get('agent_token')) {
            navigate('/404');
        }
        document.getElementById('pages-header')?.classList.remove('d-none');
    }, [navigate]);
    if (Cookies.get('agent_token')) {
        return (
            <>
                <main className="container">
                    <div className="row py-5 mt-2">
                        <div className="col-md-3">
                            <Menu routes={RoutesUser} />
                        </div>
                        <div className="col-md-9">
                            <div className="card card-body shadow border-0">
                                <Routes>
                                    {RoutesUser.map((link) => (
                                        <Route key={link.address} path={link.address} element={link.component} />
                                    ))}
                                </Routes>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </main>
            </>
        )
    }
}

export default AdminBase;